.her {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #ffffff;
}


.her .slep {
  width: 270px;
  border-radius: 6px;
  background-color: #262521;
  border-color: #262521;
  border: #262521 2px solid;
  height: 70px;
  
}

.her .slep a {
  color: #ffffff;
  font-weight: bold;
  font-size: 20px;
  text-decoration: none;
}

.her .slep:hover {
  box-shadow: 8px 8px #262521;
  background-color: #FFD602;
  border-color: #262521;
  color: #262521;
  transition: 0.3s;
}

.her .slep a:hover {
  color: #262521;
  transition: 0.3s;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.her .hero-image img {
  width: 700px;
  height: auto;
  margin: 0rem;
}

@media (max-width: 1440px) {
  .her {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
}

.her .hero-content .sub {
  font-size: 6rem;
  font-weight: bold;
}

.her .hero-content h1 {
  font-size: 3rem;
  font-weight: bold;
}

.her .hero-content a {
  margin-top: 1.2rem;
}

.her .hero-content p {
  text-align: justify;
  font-size: 1.1rem;
  margin-top: 1.2rem;
}



@media (max-width: 768px) {
  .her .hero-content h1 {
    font-size: 2rem;
  }

  .her .hero-content p {
    font-size: 1rem;
  }
}

@media (max-width: 426px) {
  .her .hero-image img {
    width: 350px !important;
    height: auto;
    /* margin: 1rem; */
    margin-top: -1rem;
  }
  .her .row .hero-content h1 h2 p a button{
    justify-content: center;
    align-items: center;
  }
}


