.contact {
  width: 100%;
  /* min-width: 100vh; */
  background-color: #ffffff;
  padding-top: 1px;
  /* margin-top: 1rem; */
  padding-bottom: 10px;
}
.contact-box .box {
  text-align: center;
  padding-bottom: 50px;
}
.contact .contact-box .box h1 {
  font-weight: 800;
  color: #000000;
  margin-bottom: 10px;
}

.contact .contact-box .form-contact form table {
  margin-inline: auto;
}

.contact .contact-box .form-contact form table tr td input,
.contact .contact-box .form-contact form table tr td textarea {
  padding: 20px;
  font-size: 16px;
  color: #000000;
  border: none;
  background-color: #d3d3d3;
  border-radius: 6px;
  border-color: #262521;
  border: #262521 2px solid;
  margin: 5px 0 5px 0;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  width: 100%;
}

.contact .contact-box .form-contact form table tr td input:focus,
.contact .contact-box .form-contact form table tr td textarea:focus {
  background-color: #d3d3d3;
}

.contact .contact-box .form-contact form table tr:nth-child(1) td:nth-child(1) {
  padding-right: 5px;
}
.contact .contact-box .form-contact form table tr:nth-child(1) td:nth-child(2) {
  padding-left: 5px;
}

.contact .contact-box .form-contact form table tr td button {
  width: 100%;
  height: 60px;
  font-size: 20px;
  background: #262521;
  border-radius: 6px;
  border-color: #262521;
  border: #262521 2px solid;
  color: #ffffff;
  /* background-color: #375e97; */
  font-weight: bold;
  cursor: pointer;
}


.contact .contact-box .form-contact button{
    border-color: #262521;
}
.contact .contact-box .form-contact form table tr td button:hover {
  box-shadow: 8px 8px #262521;
  background-color: #FFD602;
  border-color: #262521;
  color: #262521;
  transition: 0.3s;
}

@media (max-width: 768px) {
  .contact .contact-box .box p form table tr td input {
    font-size: 14px;
    width: 90%;
    margin-top: 10px;
  }
}
@media (max-width: 575px) {
  .contact .contact-box .box p form table tr td textarea {
    font-size: 12px;
    margin-top: 10px;
  }

  @media (max-width:426px) {
    .her .container .contact .contact-box .box{
      margin-top: -8rem;
      margin-bottom: -9rem;
    }
  }
}
