.card    {
    width: 100%;
    /* width: 80rem; */
    margin-top: 120px;
    margin-bottom: 200px;
}

.card .card-header{
    font-size: 40px;
    font-weight: bold;
    color: #262521  ;
    background-color: #FFD602;
}

.card .card-text {
    text-align: justify;
    font-size: clamp(10px, 0.5rem + 1vw, 20px);
}

@media (max-width:426px) {
    .card{
        width: 85%;
        margin-top: 120px;
    }
    .card-header{
        font-size: 30px !important;
    }
}