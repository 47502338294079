.event_sponser_area .Organized-by .organized-image .image-ipb {
  max-width: 300px;
}
.event_sponser_area .Organized-by .organized-image .image-ipb2 {
  max-width: 180px;
}
.event_sponser_area .Organized-by .organized-image .image-iysa {
  max-width: 300px;
}
.event_sponser_area .Organized-by .organized-image .image-prestasididik {
  max-width: 200px;
}

.event_sponser_area .Organized-by .organized-image .image-ipb:hover {
  background-color: #f5f5f5;
}


/* .event_sponser_area {
    background-color: ;
    margin-top: 50px;
    
} */

.organized-image h1{
  font-weight: bold;
}

@media (max-width:426px) {
    .image-ipb{
        width: 75%;
    }
    .image-iysa{
        width: 75%;
    }
    .event_sponser_area{
        margin-top: -3rem;
    }
}