/*-------------------------- Navbar Start --------------------------*/

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
/*header*/
.header {
  position: fixed;
  width: 100%;
  z-index: 99999;
  margin-top: -3rem;
}

.header-main {
  background-color: #262521;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1px 15px;
}
.header .logo {
  padding: 0 1px;
}
.header .logo a {
  font-size: 30px;
  text-transform: capitalize;
  color: #ffd602;
  font-weight: 600;
}
.header .nav-menu {
  padding: 0 15px;
}
.header .menu > .menu-item {
  display: inline-block;
  margin-left: 30px;
  position: relative;
}
.logo img {
  width: 80px;
}
.header .menu > .menu-item > a {
  display: block;
  padding: 12px 0;
  margin: 5px;
  font-size: 16px;
  color: #ffffff;
  text-transform: capitalize;
  font-weight: 600;
  transition: all 0.3s ease;
}

@media (max-width: 1025px) {
  .header .menu > .menu-item > a {
    display: block;
    padding: 12px 0;
    margin: 0px;
    font-size: 14px;
    color: #ffffff;
    text-transform: capitalize;
    font-weight: 600;
    transition: all 0.3s ease;
  }
}

.header .menu > .menu-item > a .FaChevronDown {
  display: inline-block;
  height: 12px;
  width: 12px;
  position: relative;
  margin-left: 5px;
  pointer-events: none;
}
.header .menu > .menu-item > a .FaChevronDown:before,
.header .menu > .menu-item > a .FaChevronDown:after {
  content: '';
  position: absolute;
  box-sizing: border-box;
  left: 50%;
  top: 50%;
  background-color: #000000;
  height: 2px;
  width: 100%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease;
}

.header .menu > .menu-item > .sub-menu .menu-item a:hover {
  color: #ffffff;
  -webkit-box-shadow: -1px 10px 5px -7px rgba(255, 255, 255, 1);
  -moz-box-shadow: -1px 10px 5px -7px rgba(255, 255, 255, 1);
  box-shadow: -1px 10px 5px -7px rgba(255, 255, 255, 1);
}

.header .menu > .menu-item:hover > a .FaChevronDown:before,
.header .menu > .menu-item:hover > a .FaChevronDown:after {
  background-color: #262521;
}
.header .menu > .menu-item > a .FaChevronDown:after {
  transform: translate(-50%, -50%) rotate(180deg);
}
.header .menu > .menu-item > .sub-menu > a:hover,
.header .menu > .menu-item:hover > a {
  color: #ffffff;
  -webkit-box-shadow: -1px 10px 5px -7px rgba(255, 255, 255, 1);
  -moz-box-shadow: -1px 10px 5px -7px rgba(255, 255, 255, 1);
  box-shadow: -1px 10px 5px -7px rgba(255, 255, 255, 1);
}
.header .menu > .menu-item > .sub-menu {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 220px;
  position: absolute;
  left: 0;
  top: 100%;
  background-color: #262521;
  padding: 10px 0;
  border-top: 3px solid #ffffff;
  transform: translateY(10px);
  transition: all 0.3s ease;
  opacity: 0;
  visibility: hidden;
}
@media (min-width: 992px) {
  .header .menu > .menu-item-has-children:hover > .sub-menu {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
  .header
    .menu
    > .menu-item-has-children:hover
    > a
    .FaChevronDown:after {
    transform: translate(-50%, -50%) rotate(180deg);
  }
}
.header .menu > .menu-item > .sub-menu > .menu-item {
  display: block;
}
.header .menu > .menu-item > .sub-menu > .menu-item > a {
  display: block;
  padding: 5px 20px;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  transition: all 0.3s ease;
  text-transform: capitalize;
}
.header .open-nav-menu {
  height: 34px;
  width: 40px;
  margin-right: 15px;
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.header .open-nav-menu span {
  display: block;
  height: 3px;
  width: 24px;
  background-color: #000000;
  position: relative;
}
.header .open-nav-menu span:before,
.header .open-nav-menu span:after {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  box-sizing: border-box;
}
.header .open-nav-menu span:before {
  top: -7px;
}
.header .open-nav-menu span:after {
  top: 7px;
}
.header .close-nav-menu {
  height: 40px;
  width: 40px;
  background-color: #ffffff;
  margin: 0 0 15px 15px;
  cursor: pointer;
  display: none;
  align-items: center;
  justify-content: center;
}
.header .close-nav-menu img {
  width: 16px;
}
.header .menu-overlay {
  position: fixed;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
}

/*home section*/
.home-section {
  width: 100%;
  display: block;
  min-height: 100vh;
  background-position: center top;
  background-size: cover;
}

.menu button {
  margin: 10px;
  border-radius: 6px;
  border-color: #ffffff;
  background-color: #ffffff;
  /* background-color: #375e97; */
  color: #262521;
  height: 50px;
}

.menu button:hover {
  background-color: #ffd602;
  border-color: #ffffff;
}

.menu a {
  color: #262521;
  text-decoration: none;
  font-weight: bold;
}
/* responsive */

@media (max-width: 991px) {
  .header .menu-overlay.active {
    visibility: visible;
    opacity: 1;
  }
  .header .nav-menu {
    position: fixed;
    right: -280px;
    visibility: hidden;
    width: 280px;
    height: 100%;
    top: 0;
    overflow-y: auto;
    background-color: #222222;
    z-index: 1000;
    padding: 15px 0;
    transition: all 0.5s ease;
  }
  .header .nav-menu.open {
    visibility: visible;
    right: 0px;
  }
  .header .menu > .menu-item {
    display: block;
    margin: 0;
  }
  .header .menu > .menu-item-has-children > a {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header .menu > .menu-item > a {
    color: #ffffff;
    padding: 12px 15px;
    border-bottom: 1px solid #333333;
  }
  .header .menu > .menu-item:first-child > a {
    border-top: 1px solid #333333;
  }
  .header .menu > .menu-item > a .FaChevronDown:before,
  .header .menu > .menu-item > a .FaChevronDown:after {
    background-color: #ffffff;
  }
  .header
    .menu
    > .menu-item-has-children.active
    > a
    .FaChevronDown:after {
    transform: translate(-50%, -50%) rotate(180deg);
  }
  .header .menu > .menu-item > .sub-menu {
    width: 100%;
    position: relative;
    opacity: 1;
    visibility: visible;
    border: none;
    background-color: transparent;
    box-shadow: none;
    transform: translateY(0px);
    padding: 0px;
    left: auto;
    top: auto;
    max-height: 0;
    overflow: hidden;
  }
  .header .menu > .menu-item > .sub-menu > .menu-item > a {
    padding: 12px 45px;
    color: #ffffff;
    border-bottom: 1px solid #333333;
  }
  .header .close-nav-menu,
  .header .open-nav-menu {
    display: flex;
  }
  .header-main {
    padding: 10px 15px !important;
  }
}

/*-------------------------- Navbar End --------------------------*/
