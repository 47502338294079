.products h2 {
  text-align: center;
  margin-bottom: 4rem;
  font-size: 3rem;
  font-weight: bold;
}
.garis-bawah::after {
  position: absolute;
  content: "";
  background:  #FFD602;
  height: 4px;
  width: 180px;
  left: 50%;
  transform: translateX(-50%);
}

.all-products {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 200px;
}

.product {
  overflow: hidden;
  background: #ffffff;
  color: #21201e;
  text-align: center;
  width: 240px;
  height: 260px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 1.2rem;
  margin: 3rem;
}

.product .product-title,
.product .product-price {
  font-size: 18px;
}

.product:hover img {
  scale: 1.1;
}

/* .product:hover {
  box-shadow: 5px 15px 25px #eeeeee;
} */

.product img {
  height: 200px;
  margin: 1rem;
  transition: all 0.3s;
}

.product a:link,
.product a:visited {
  color: #ececec;
  display: inline-block;
  text-decoration: none;
  background-color: #2c3e50;
  padding: 1rem 2rem;
  border-radius: 1rem;
  margin-top: 1rem;
  font-size: 14px;
  transition: all 0.2s;
}

.product a:hover {
  transform: scale(1.1);
}
.product h4 {
    font-weight: bold;
}

@media (max-width:426px) {
    .products{
        margin: 20px;
        padding: 0.5rem;
        margin-top: -6rem;
    }
    .products h2 {
        font-size: 2rem;
    }
    .product{
      margin: 0.5rem;
    }
    .product img{
      width: 120px;
      margin: 10px;
    }
    .all-products {
        margin-top: -1rem;
        margin-bottom: 2px;
    }
}
