/*-------------------------- Footer Start --------------------------*/

footer {
    width: 100%;
    bottom: 0;
    background: #2c2c2c;
    color: #fff;
    padding: 100px 0 30px;
    font-size: 13px;
    line-height: 20px;
  }

  .colFooter img{
    width: 17rem;
  }

  .colFooter ul li a:hover{
    color: #8a7fff;
  }

  .colFooter a:hover{
    color: #8a7fff;
  }
  
  .rowFooter {
    width: 85%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
  }
  
  .colFooter {
    flex-basis: 25%;
    padding: 10px;
  }
  
  .colFooter:nth-child(2),
  .colFooter:nth-child(3) {
    flex-basis: 15%;
  }
  
  .logoFooter {
    width: 150px;
    margin-bottom: 30px;
  }
  
  .colFooter h3 {
    margin-bottom: 40px;
    position: relative;
  }
  
  .office {
    font-size: 1rem;
    text-decoration: none;
    color: white;
  }
  
  ul li {
    list-style: none;
    margin-bottom: 12px;
  }
  
  ul li a {
    font-size: 1rem;
    text-decoration: none;
    color: #fff;
    margin-left: -30px;
  }
  
  form {
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    margin-bottom: 50px;
  }
  
  form .logoNewsletter {
    font-size: 18px;
    margin-right: 10px;
  }
  
  form input {
    width: 100%;
    background: transparent;
    color: #ccc;
    border: 0;
    outline: none;
  }
  
  .colFooter input[type="email"],
  .colFooter input[type="email"]::placeholder {
    color: #fff;
  }
  
  form button {
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
  }
  
  form button .logoButtonNewsletter {
    font-size: 16px;
    color: #ccc;
  }
  
  .social-icons .footer-social-icons-fb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    font-size: 20px;
    color: #000;
    background: #fff;
    margin-right: 15px;
    cursor: pointer;
    padding: 5px;
  }
  .social-icons .footer-social-icons-ig {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    font-size: 20px;
    color: #000;
    background: #fff;
    margin-right: 15px;
    cursor: pointer;
    padding: 5px;
  }
  .social-icons .footer-social-icons-yt {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    font-size: 20px;
    color: #000;
    background: #fff;
    margin-right: 15px;
    cursor: pointer;
    padding: 5px;
  }
  .social-icons .footer-social-icons-tt {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    font-size: 20px;
    color: #000;
    background: #fff;
    margin-right: 15px;
    cursor: pointer;
    padding: 5px;
  }
  .social-icons .footer-social-icons-ld {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    font-size: 20px;
    color: #000;
    background: #fff;
    margin-right: 15px;
    cursor: pointer;
    padding: 5px;
  }
  .social-icons .footer-social-icons-fb:hover {
    background-color: rgb(59, 89, 152);
    color: #fff;
  }
  .social-icons .footer-social-icons-ig:hover {
    background-color: rgb(195, 42, 163);
    color: #fff;
  }
  .social-icons .footer-social-icons-yt:hover {
    background-color: rgb(255, 0, 0);
    color: #fff;
  }
  .social-icons .footer-social-icons-tt:hover {
    background: #000000;
    color: #fff;
  }
  .social-icons .footer-social-icons-ld:hover {
    background-color: rgb(0, 119, 181);
    color: #fff;
  }
  
  hr {
    width: 86%;
    border: 0;
    border-bottom: 1px solid #ccc;
  }
  
  .copyright {
    text-align: center;
  }
  
  .underline {
    width: 50%;
    height: 5px;
    background: #767676;
    border-radius: 3px;
    position: absolute;
    top: 50px;
    left: 0px;
    overflow: hidden;
  }
  
  .underline span {
    width: 15px;
    height: 100%;
    background: #fff;
    border-radius: 3px;
    position: absolute;
    top: 0;
    left: 10px;
    animation: moving 2s linear infinite;
  }
  
  @keyframes moving {
    0% {
      left: -20px;
    }
    100% {
      left: 100%;
    }
  }
  
  @media (max-width: 700px) {
    footer {
      bottom: unset;
    }
  
    .colFooter {
      flex-basis: 100%;
    }
  
    .colFooter:nth-child(2),
    .colFooter:nth-child(3) {
      flex-basis: 100%;
    }
  }
  
  /*-------------------------- Footer End --------------------------*/
  